import React from 'react';
import { useMediaQuery } from 'react-responsive/src';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import Carousel from 'react-elastic-carousel';
import Vacancy from "../../assets/data/vacancy.json";

const infoBlocks = [
  {
    number: '7+',
    text: 'Years with amazing success in Sweden, Norway, and Finland',
  },
  {
    number: '100%',
    text: 'Of our clients are satisfied with our services',
  },
  {
    number: '50+',
    text: 'Successfully finished and ongoing projects of all kinds',
  },
];

const infoBlocksResponse = [
  { width: 320, itemsToShow: 1.5, itemsToScroll: 1 },
  { width: 630, itemsToShow: 1.5, itemsToScroll: 1 },
  { width: 850, itemsToShow: 3, itemsToScroll: 3 },
  { width: 1440, itemsToShow: 6, itemsToScroll: 6 },
];

const Career = (props) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 840px)' });
  const history = useHistory();

  const moveToVacancy = (number, e) => {
    e.stopPropagation();
    e.preventDefault();
    history.push('/company/vacancy/' + number);
    return false;
  };

  return (
  <div id={isDesktop ? "" : "company"} className="layout black section fp-auto-height-responsive">
    <section className="slide content static content-career">
      <h1 className="hidden">Vacancies</h1>
      <div className="career">
        <div className="info-block">
          <div className="info-block-content">
            <div className="about article">
              <div className="article-icon">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="24" cy="24" r="23" stroke="#F26D6B" strokeWidth="2"/>
                  <rect x="23" y="17" width="2" height="19" rx="1" fill="#F26D6B"/>
                  <rect x="23" y="12" width="2" height="2" rx="1" fill="#F26D6B"/>
                </svg>
              </div>
              <div className="article-content">
                <div className="article-header">
                  About us
                </div>
                <div className="article-text">
                  Spark is a digital software house with a team of versatile and hand-picked experts in web and app development.
                  We have been developing full software solutions for Nordic
                  companies in various business areas during the last several years with amazing success.
                </div>
              </div>

            </div>
            {false && !isDesktop && <div className="infographic-block">
              <div className="card-list">
                <Carousel itemsToShow={1}
                          showArrows={false}
                          itemsToScroll={1}
                          breakPoints={infoBlocksResponse}>
                  {
                    infoBlocks && infoBlocks.map((block, index) => (
                      <div className="card-list-item" key={`${block.number}_${index}`}>
                        <div className="card-list-item__number no-spacing">{block.number}</div>
                        <div className="card-list-item__text">{block.text}</div>
                      </div>
                    ))
                  }
                </Carousel>
              </div>
            </div>}
            {!isDesktop && <div className="infographic-block">
              <div className="card-list">
                {
                  infoBlocks && infoBlocks.map((block, index) => (
                    <div className="card-list-item" key={`${block.number}_${index}`}>
                      <div className="card-list-item__number no-spacing">{block.number}</div>
                      <div className="card-list-item__text">{block.text}</div>
                    </div>
                  ))
                }
              </div>
            </div>
            }
            <div className="vacancies article">
              <div className="article-icon">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="24" cy="24" r="23" stroke="#F26D6B" strokeWidth="2"/>
                  <circle cx="24" cy="24" r="23" stroke="#F26D6B" strokeWidth="2"/>
                  <path d="M19 11H29L28 23H20L19 11Z" stroke="#F26D6B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M13.5 17.5C19.5 17.5 14.5 27 19 27C22.6 27 23.8333 27 24 27" stroke="#F26D6B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M34.5 17.5C28.5 17.5 33.5 27 29 27C25.4 27 24.1667 27 24 27" stroke="#F26D6B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M24 27V34" stroke="#F26D6B" strokeWidth="2"/>
                  <path d="M29 34V33C29 31.8954 28.1046 31 27 31H21C19.8954 31 19 31.8954 19 33V34" stroke="#F26D6B" strokeWidth="2"/>
                  <circle cx="29" cy="35" r="2" fill="#F26D6B"/>
                  <circle cx="24" cy="35" r="2" fill="#F26D6B"/>
                  <circle cx="19" cy="35" r="2" fill="#F26D6B"/>
                </svg>
              </div>
              <div className="article-content">
                <div className="article-header">
                  Vacancies
                </div>
                <div className="article-text">
                  <ul className="vacancies-list">
                    {Vacancy.map((x, i) => <li onClick={(event)=>moveToVacancy(x.id, event)}>
                      <a href={`/company/vacancy/${x.id}`}>{x.title}</a>
                    </li>)}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isDesktop && <div className="infographic-block">
          <div className="card-list">
            <div className="card-list-col">
              <div className="card-list-item">
                <div className="card-list-item__number no-spacing">7+</div>
                <div className="card-list-item__text">Years with amazing success in Sweden, Norway, and Finland</div>
              </div>
            </div>
            <div className="card-list-col last">
              <div className="card-list-item">
                <div className="card-list-item__number no-spacing">100%</div>
                <div className="card-list-item__text">Of our clients are satisfied with our services</div>
              </div>
              <div className="card-list-item">
                <div className="card-list-item__number no-spacing">50+</div>
                <div className="card-list-item__text">Successfully finished and ongoing projects of all kinds</div>
              </div>
            </div>
          </div>
        </div>}
      </div>
    </section>
  </div>
);};

export default React.memo(Career);
